import { init as initApm } from "@elastic/apm-rum";
import { afterFrame } from "@elastic/apm-rum-core";
import Head from "next/head";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { CookiesProvider } from "react-cookie";

import SierraChatbot from "@/components/SierraChatbot";
import ProgressBar from "@/components/progress-bar";
import { SESSION_STORAGE_KEYS } from "@/constants";
import Account from "@/context/Account";
import BrazeContext from "@/context/BrazeContext";
import DigitalDataLayer from "@/context/DigitalDataLayer";
import User from "@/context/User";
import "@/styles/global.scss";

import initGoogleScript from "../googleAdScript";

const { version } = require("../package.json");

const apm = initApm({
  active: process.env.NEXT_PUBLIC_ELASTIC_IS_ACTIVE === "true",
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
  serverUrl: process.env.NEXT_PUBLIC_ELASTIC_SERVER_URL,
  serviceName: process.env.NEXT_PUBLIC_ELASTIC_SERVICE_NAME,
  serviceVersion: version,
});

const MyApp = ({ Component, pageProps }) => {
  const router = useRouter();
  const [brazeContextValue, setBrazeContextValue] = useState({
    instance: null,
    isInitialized: false,
  });

  const apmTransaction = useMemo(() => {
    if (typeof apm !== "object") {
      return;
    }

    return apm.startTransaction(router.asPath, "route-change", {
      canReuse: true,
      managed: true,
    });
  }, [router.asPath]);

  useEffect(() => {
    if (typeof apmTransaction !== "object") {
      return;
    }

    afterFrame(() => apmTransaction.detectFinish());

    return () => {
      apmTransaction.detectFinish();
    };
  }, [apmTransaction]);

  useEffect(() => {
    // Next.js' client side routing (eg: next/link) leads to document.referrer
    // remaining set at the initial full page load's value. This is how we work around it.
    if (sessionStorage?.getItem(SESSION_STORAGE_KEYS.CURRENT_URL)) {
      sessionStorage.setItem(
        SESSION_STORAGE_KEYS.PREVIOUS_URL,
        sessionStorage.getItem(SESSION_STORAGE_KEYS.CURRENT_URL)
      );
    }

    sessionStorage?.setItem(SESSION_STORAGE_KEYS.CURRENT_URL, document?.URL);
  }, [router.asPath]);

  useEffect(() => {
    if (pageProps.slug) {
      document.body.dataset.page = pageProps.slug;
    }
  }, [pageProps.slug]);

  useEffect(() => {
    initGoogleScript();
  }, []);

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_BRAZE_API_KEY &&
      process.env.NEXT_PUBLIC_BRAZE_BASE_URL &&
      process.env.NEXT_PUBLIC_FEATURE_IS_BRAZE_ACTIVE === "true"
    ) {
      import("@braze/web-sdk").then((braze) => {
        braze.initialize(process.env.NEXT_PUBLIC_BRAZE_API_KEY, {
          baseUrl: process.env.NEXT_PUBLIC_BRAZE_BASE_URL,
          enableLogging:
            process.env.NEXT_PUBLIC_ENVIRONMENT !== "prod" &&
            process.env.NEXT_PUBLIC_ENVIRONMENT !== "preprod",
        });

        braze.automaticallyShowInAppMessages();

        const userFromLocalStorage = JSON.parse(
          localStorage.getItem(SESSION_STORAGE_KEYS.USER_SESSION)
        );

        if (userFromLocalStorage) {
          userFromLocalStorage.user &&
            userFromLocalStorage.user.trackingId &&
            braze.changeUser(userFromLocalStorage.user.trackingId);
        }

        braze.openSession();

        setBrazeContextValue({
          instance: braze,
          isInitialized: true,
        });

        if (
          process.env.NEXT_PUBLIC_ENVIRONMENT !== "prod" &&
          process.env.NEXT_PUBLIC_ENVIRONMENT !== "preprod"
        ) {
          window.braze = braze;
        }
      });
    }
  }, []);

  return (
    <>
      <Head>
        <meta content="initial-scale=1, width=device-width" name="viewport" />
      </Head>
      <CookiesProvider
        defaultSetOptions={{
          domain:
            process.env.NEXT_PUBLIC_ENVIRONMENT === "localhost"
              ? "localhost"
              : "equinox.com",
          path: "/",
          secure: process.env.NEXT_PUBLIC_ENVIRONMENT !== "localhost",
        }}
      >
        <User>
          <Account>
            <DigitalDataLayer>
              <BrazeContext.Provider value={brazeContextValue}>
                <ProgressBar />
                <Component {...pageProps} />
              </BrazeContext.Provider>
            </DigitalDataLayer>
          </Account>
        </User>
      </CookiesProvider>
      <SierraChatbot />
    </>
  );
};

MyApp.propTypes = {
  Component: PropTypes.func,
  pageProps: PropTypes.object.isRequired,
};

export default MyApp;
