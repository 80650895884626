export const CHICAGO = {
  id: 8,
  name: "Chicago",
};

export const CONNECTICUT = {
  id: 6,
  name: "Connecticut",
};

export const LONDON = {
  id: 10,
  name: "London",
};

export const NEW_YORK = {
  id: 5,
  name: "New York",
};

export const NEW_YORK_CITY = {
  id: 4,
  name: "New York City",
};

export const NORTHERN_CALIFORNIA = {
  id: 9,
  name: "Northern California",
};

export const SOUTHERN_CALIFORNIA = {
  id: 3,
  name: "Southern California",
};

const REGIONS = [
  CHICAGO,
  CONNECTICUT,
  LONDON,
  NEW_YORK_CITY,
  NEW_YORK,
  NORTHERN_CALIFORNIA,
  SOUTHERN_CALIFORNIA,
];

const byId = {};

REGIONS.forEach((region) => {
  byId[region.id] = region;
});

export { byId };

export default REGIONS;
