import axios from "axios";

import { autoInitialize } from "../config";
import { appEnvironment, baseEnvironmentConfig } from "../constants";
import { initializeApiClient } from "../init";

const debug = require("debug")("eqx:api");

// `clientInstance` serves as a way to make ApiClient behave as a singleton.
// Any call to `new ApiClient()` will return the original/first instance made.
let clientInstance = null;

class ApiClient {
  constructor() {
    // On the first constructor call do some prep and register as the singleton.
    if (!clientInstance) {
      this.configurations = {};

      this.currentEnvName = null;
      this._axiosAdapter = null;

      this._session = null;
      this._sessionLoading = false;

      clientInstance = this;
    }
    // By default, auto-use the local environment's config.
    else if (!clientInstance.config()) {
      clientInstance.useEnvironment(appEnvironment);
    }

    // Always return our singleton.
    return clientInstance;
  }

  async currentSession({ forceReload = false } = {}) {
    if (this._session && !forceReload) {
      return this._session;
    }

    if (!this._sessionLoading) {
      this._sessionLoading = true;

      const session = await axios
        .get("/api/me")
        .then((resp) => {
          this._sessionLoading = false;

          return resp.data;
        })
        .catch((err) => {
          this._sessionLoading = false;

          if (err.response.status !== 401) {
            console.error(
              "ERROR code and message: ",
              err.response.status,
              err.response.statusText
            );
          }

          return {};
        });

      this._session = session;

      return this._session;
    }

    return this._session;
  }

  config() {
    return this.currentEnvName
      ? this.configurations[this.currentEnvName]
      : baseEnvironmentConfig;
  }

  useEnvironment(envName) {
    if (!envName || (this.currentEnvName && this.currentEnvName === envName)) {
      return false;
    }

    if (!this.configurations[envName]) {
      this.registerEnvironmentConfig(envName, baseEnvironmentConfig);
    }

    this.currentEnvName = envName;

    this._axiosAdapter = axios.create({
      baseURL: this.config().equinox_api.host,
    });

    return true;
  }

  registerEnvironmentConfig(envName, envConfig) {
    if (!envName || envName.length < 1 || this.configurations[envName]) {
      return false;
    }

    // Quick shallow merge
    this.configurations[envName] = Object.assign(
      {},
      baseEnvironmentConfig,
      envConfig
    );

    // Directly merging the known sub-group values we expect.
    // TODO: temporary hard-coding; replace with a 'real' deep merge via a lib/util.
    //============/ begin temp merging /=========
    ["analytics", "equinox_api", "session"].forEach((groupName) => {
      if (envConfig[groupName]) {
        this.configurations[envName][groupName] = {
          ...baseEnvironmentConfig[groupName],
          ...envConfig[groupName],
        };
      }
    });

    // Special handling for retaining sub-hashes during customizations.
    if (envConfig.analytics) {
      this.configurations[envName].analytics.cookies = {
        ...baseEnvironmentConfig.analytics.cookies,
        ...envConfig.analytics.cookies,
      };
    }

    if (envConfig.session) {
      this.configurations[envName].session.cookies = {
        ...baseEnvironmentConfig.session.cookies,
        ...envConfig.session.cookies,
      };
    }
    //============/ end temp merging /=========

    return true;
  }

  _get(endpoint, config = {}) {
    return this._axiosAdapter({ url: endpoint, ...config }).then((resp) => {
      debug(`[ApiClient.get]: ${endpoint} response => ${resp}`);

      return resp;
    });
  }

  _post(endpoint, config = {}) {
    return this._axiosAdapter({
      method: "POST",
      url: endpoint,
      ...config,
    })
      .then((resp) => {
        debug(`[ApiClient.post]: ${endpoint} response => ${resp}`);

        return resp;
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          return error.response;
        }

        console.error("Error in _post:", error);
      });
  }

  _put(endpoint, config = {}) {
    return this._axiosAdapter({ method: "PUT", url: endpoint, ...config }).then(
      (resp) => {
        debug(`[ApiClient.put]: ${endpoint} response => ${resp}`);

        return resp;
      }
    );
  }

  _delete(endpoint, config = {}) {
    return this._axiosAdapter({
      method: "DELETE",
      url: endpoint,
      ...config,
    }).then((resp) => {
      debug(`[ApiClient.delete]: ${endpoint} response => ${resp}`);

      return resp;
    });
  }

  _reset() {
    this._axiosAdapter = null;
    this.currentEnvName = null;
    this.configurations = {};
  }
}

export default autoInitialize === true
  ? initializeApiClient(new ApiClient())
  : new ApiClient();
