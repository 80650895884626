import { useRouter } from "next/router";
import React, { useEffect } from "react";

import styles from "./SierraChatbot.module.scss";
import { WEB_PAGE_TRACKING } from "./constants";

const SierraChatbot = () => {
  const router = useRouter();

  useEffect(() => {
    const getWebPageTrackingValue = () => {
      if (router.pathname === "/") {
        return WEB_PAGE_TRACKING.HOME;
      }

      if (
        [
          "/clubs/[region]/[subregion]",
          "/clubs/[region]/[subregion]/[clubName]",
        ].includes(router.pathname)
      ) {
        return WEB_PAGE_TRACKING.CLUBS;
      }

      if (router.asPath === "/faq") {
        return WEB_PAGE_TRACKING.FAQ;
      }

      if (router.pathname === "/memberbenefits") {
        return WEB_PAGE_TRACKING.MEMBER_BENEFITS;
      }
    };

    //For tracking purposes, we need to set the web_page info
    //in the sierra config object when user navigates and uses the chatbot
    if (window.sierraConfig) {
      window.sierraConfig.variables.web = getWebPageTrackingValue();
    }
  }, [router.asPath, router.pathname]);

  return (
    process.env.NEXT_PUBLIC_SIERRA_CHAT_URL &&
    ([
      "/",
      "/clubs/[region]/[subregion]",
      "/clubs/[region]/[subregion]/[clubName]",
      "/memberbenefits",
    ].includes(router.pathname) ||
      router.asPath === "/faq") && (
      <div className={styles.chatbotContainer}>
        <a
          data-sierra-chat="modal"
          href={process.env.NEXT_PUBLIC_SIERRA_CHAT_URL + "chat?display:corner"}
        >
          <svg
            fill="none"
            height="32"
            viewBox="0 0 32 32"
            width="32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clipRule="evenodd"
              d="M20.6667 23.334H28V5.3548H4V23.334H11.1667V29.3333L20.6667 23.334ZM12.6667 26.612L20.2327 21.834H26.5V6.8548H5.5V21.834H12.6667V26.612Z"
              fill="black"
              fillRule="evenodd"
            />
          </svg>
        </a>
      </div>
    )
  );
};

export default SierraChatbot;
