import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

export const DigitalDataLayerContext = React.createContext();

const DigitalDataLayer = ({ children }) => {
  const [leadFlowInfo, setLeadFlowInfo] = useState({ data: {} });

  // Just so it`s 100% Backwards Compatible, for the moment
  // and until we deprecate the old layer, we´re adding this new Function
  // and Setter for the child components
  const setNewDataLayer = (layer) => {
    console.info("ACDL EVENT PUSHED: ", layer);
    window && window.adobeDataLayer && window.adobeDataLayer.push(layer);
  };

  // To make sure the adobeDataLayer is initialized on every component, if needed
  useEffect(() => {
    if (window) {
      window.adobeDataLayer = window.adobeDataLayer || [];
    }
  }, []);

  return (
    <DigitalDataLayerContext.Provider
      value={{ leadFlowInfo, setLeadFlowInfo, setNewDataLayer }}
    >
      {children}
    </DigitalDataLayerContext.Provider>
  );
};

DigitalDataLayer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DigitalDataLayer;
