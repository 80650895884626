export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const longMonthNameFormatter = new Intl.DateTimeFormat("en-US", {
  month: "long",
});

export const longWeekdayNameFormatter = new Intl.DateTimeFormat("en-US", {
  weekday: "long",
});

export const longMonthNames = [...Array(12).keys()].map((monthIndex) =>
  longMonthNameFormatter.format(new Date(2000, monthIndex))
);

export const FORMAT_ISO_NO_MS = "yyyy-MM-dd'T'HH:mm:ss'Z'";

export const UTC_MIDDAY = "T12:00:00.000Z";
