export const HG = {
  label: "hg",
  value: 1920,
};

export const XL = {
  label: "xl",
  value: 1440,
};

export const LG = {
  label: "lg",
  value: 1200,
};

export const MD = {
  label: "md",
  value: 960,
};

export const SM = {
  label: "sm",
  value: 640,
};

export const XS = {
  label: "xs",
  value: 320,
};

export const NONE = {
  label: "none",
  value: 0,
};

// descending order matters...
// keep in sync with /src/styles/_breakpoints.scss
const BREAKPOINTS = [HG, XL, LG, MD, SM, XS, NONE];

const byLabel = {};

BREAKPOINTS.forEach((breakpoint) => {
  byLabel[breakpoint.label] = breakpoint;
});

export { byLabel };

export default BREAKPOINTS;
